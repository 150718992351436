<template>
  <LayoutDataTable :isLoading="showTable || isLoading" :searchQuery="searchQuery" :perPage="perPage" :meta="dataMeta"
    :currentPage="currentPage" :totals="totals" :isShowTabs="false"
    :searchPlaceholder="$t('lbl_find_orders_by_order_code')" @setTab="handleTabClicked"
    @setPerPage="(value) => (perPage = value)" @setSearchQuery="(value) => (searchQuery = value, currentPage = 1)"
    @setCurrentPage="(value) => (currentPage = value)">
    <template #cta>
      <b-button variant="primary" class="ml-1" @click="handleExportExcel">
        <span class="text-nowrap">{{ $t('lbl_export_csv') }}</span>
      </b-button>
    </template>
    <template #default>
      <b-row class="w-full pl-2 d-flex" style="margin-top: 5px">
        <b-col cols="12" md="6">
          <span>Ngày tạo</span>
          <b-row class="d-flex">
            <b-col style="min-width: 220px">
              <b-form-datepicker :locale="$i18n.locale || 'vi'" v-bind="$getCalendarLabel($i18n.locale || 'vi')"
                id="start-date" :placeholder="$t('lbl_from_date')" v-model="fromDate" class="mb-2 mr-2" value-as-date
                today-button reset-button></b-form-datepicker>
            </b-col>
            <span class="mb-2 font-weight-bold" style="height: 39px; line-height: 39px">-</span>
            <b-col style="min-width: 220px">
              <b-form-datepicker :locale="$i18n.locale || 'vi'" v-bind="$getCalendarLabel($i18n.locale || 'vi')"
                id="end-date" :placeholder="$t('lbl_to_date')" v-model="toDate" class="mb-2" value-as-date today-button
                reset-button></b-form-datepicker>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="3">
          <span>Trạng thái đơn hàng</span>
          <b-form-select placeholder="Tất cả trạng thái đơn hàng" v-model="statusFilter"
            :options="statusFilterOptions" />
        </b-col>
        <b-col cols="12" md="3">
          <span>Trạng thái thanh toán</span>
          <b-form-select placeholder="Tất cả trạng thái thanh toán" v-model="paymentStatusFilter"
            :options="paymentStatusFilterOptions" />
        </b-col>
      </b-row>
      <!-- <b-row class="w-full pl-2 pr-3 d-flex">
        <b-col v-if="followMode === 'follow'">
          <b-button @click="handleClickBellIcon(null, true)" v-b-tooltip.top :title="selectedRows.length===0 ? 'You have not selected any order.' : ''" :disabled="selectedRows.length===0" size="sm" variant="outline-primary">Unfollow</b-button>
          <p class="d-inline-block mb-2 ml-2" style="font-size: 12px">Selected {{ selectedRows.length }} out of {{ perPage }} rows</p>
        </b-col>
        <b-col v-if="followMode === 'floating'">
          <b-button @click="handleClickBellIcon(null, false)" v-b-tooltip.top :title="selectedRows.length===0 ? 'You have not selected any order.' : ''" :disabled="selectedRows.length===0" size="sm" variant="primary" class="mr-1">Follow</b-button>
          <p class="d-inline-block mb-2 ml-2" style="font-size: 12px">Selected {{ selectedRows.length }} out of {{ perPage }} rows</p>
        </b-col>
      </b-row>-->
      <b-table ref="refOrdersTable" id="refOrdersTable" class="position-relative" :items="fetchOrdersList" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty hover
        :empty-text="$t('lbl_no_matching_records_found')" :sort-desc.sync="isSortDirDesc"
        @row-clicked="openDetailOrders">
        <!-- <template slot="row-details" slot-scope="scope">
          <div
            class="d-flex items-center"
            :class="index < scope.item.order_item.length - 1 ? 'mb-1' : ''"
            v-for="(product, index) in scope.item.order_item"
            :key="product.id"
          >
            <div class="mr-1">
              <img
                class="border rounded-sm"
                :height="48"
                :src="product.product_image"
                :alt="product.product_name"
              />
            </div>
            <div>
              <div
                class="text-primary font-weight-bold"
              >{{ product.product_name }} ({{ product.product_variant_unit }})</div>
              <div>
                <span>{{ appUtils.numberFormat(product.price, 'VNĐ') }}</span> x
                <span>{{ product.qty }}</span>
              </div>
            </div>
          </div>
        </template>-->
        <template #cell(selecting)="scope">
          <span class="d-inline-block" style="width: 20px">
            <b-form-checkbox v-model="scope.item.isSelected" @change="(e) => checkRow(e, scope.item)"
              style="width: 20px"></b-form-checkbox>
          </span>
        </template>
        <template #cell(order_code)="scope">
          <span class="text-nowrap">
            <div v-if="scope.item.order_read_flag === 1" class="fade-loading"></div>
            {{ scope.item.order_code }}
          </span>
        </template>
        <template #cell(customer)="scope">
          <span class="text-nowrap">{{ getCustomer(scope.item) }}</span>
        </template>
        <template #cell(address)="scope">
          <span class="text-nowrap">{{ getCustomerAddress(scope.item) }}</span>
        </template>
        <template #cell(phone)="scope">
          <span class="text-nowrap">{{ getCustomerPhone(scope.item) }}</span>
        </template>
        <template #cell(delivery_status)="scope">
          <div :set="(status = getDeliveryStatus(scope.item))">
            <b-badge :variant="status.color">{{ status.title }}</b-badge>
          </div>
        </template>
        <template #cell(payment_status)="scope">
          <div :set="(status = getPaymentStatus(scope.item))">
            <b-badge :variant="status.color">{{ status.title }}</b-badge>
          </div>
        </template>
        <template #cell(total_order_amount)="scope">
          <div class="text-nowrap text-center">{{ appUtils.numberFormat(getTotalOrderAmount(scope.item)) }}</div>
        </template>
        <template #cell(transaction_point)="scope">
          <div class="text-nowrap text-center">{{ getTotalPointTxn(scope.item) === 0 ? '-' :
            appUtils.numberFormat(getTotalPointTxn(scope.item)) }}</div>
        </template>
        <template #cell(transaction_campaign)="scope">
          <div class="text-nowrap text-center">{{ getTotalCampaignTnx(scope.item) === 0 ? '-' :
            appUtils.numberFormat(getTotalCampaignTnx(scope.item)) }}</div>
        </template>
        <template #cell(customer_pay)="scope">
          <div class="text-nowrap text-center">{{ getSlipAmount(scope.item) }}</div>
        </template>
        <template #cell(order_date)="scope">
          <div class="text-nowrap text-center">{{ formatDate(scope.item.order_date, "HH:mm DD/MM/YYYY") }}</div>
        </template>
        <!-- <template #cell(return_date)="scope">
          <span class="text-nowrap" style="width: 70px">{{
            formatDate(scope.item.return_date)
          }}</span>
          <WarningIcon
            v-if="
              getResultDateInfor(scope.item.return_date, scope.item).isShowBadge
            "
            class="margin-b-4"
            :fill="getResultDateInfor(scope.item.return_date).variant"
            :title="getResultDateInfor(scope.item.return_date).tooltip"
            v-b-tooltip.top
          ></WarningIcon>
        </template>-->
        <template #cell(action)="scope">
          <div class="text-right text-nowrap" @click.stop>
            <!-- <b-button
              :set="(isFollow = getIsFollow(scope.item.followers))"
              v-b-tooltip.hover.top
              :variant="isFollow ? 'outline-info' : 'outline-primary'"
              size="sm"
              :title="
                isFollow
                  ? $t('lbl_unfollow_order_s_notification')
                  : $t('lbl_follow_order_s_notification')
              "
              @click="handleClickBellIcon(scope.item.id, getIsFollow(scope.item.followers))"
            >
              <feather-icon size="16" :icon="isFollow ? 'BellOffIcon' : 'BellIcon'" />
            </b-button>-->
            <b-button v-if="scope.item.status < 3" v-b-tooltip.hover.top variant="outline-danger ml-1" size="sm"
              :title="$t('lbl_cancel_orders')" @click="deleteOrders(scope.item)" record>
              <feather-icon size="16" icon="XCircleIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-modal :busy="orders.isLoadingDetail" id="hodo-modal-scrollable" size="xl" scrollable hide-header ok-only
        :ok-title="$t('lbl_close')" v-model="showDetailOrder">
        <OrdersDetail v-if="currentOrderId" :orderId="currentOrderId" @success="refetchData" />
      </b-modal>
      <b-modal id="modal-reject-reason" ref="modal" title="Huỷ đơn hàng" @show="resetModal" @hidden="resetModal"
        @ok="handleConfirm()">
        <b-form-group label="Lý do huỷ đơn hàng" label-for="reject_reason"
          invalid-feedback="Lý do huỷ đơn hàng là bắt buộc">
          <b-form-input id="reject_reason" v-model="reject_reason" required></b-form-input>
        </b-form-group>
      </b-modal>
    </template>
  </LayoutDataTable>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import EventBus from '@/utils/EventBus'
import appUtils from '@/utils/appUtils'
import {
  ORDERS_PRODUCT_STATUS,
  ORDER_FORM_PROCESSOR_STATUS,
  PRODUCT_TYPES
} from '@/utils/constant'
import HODO_LOGO_BASE64 from '@/utils/logoHodoBase64.js'
import { ref } from '@vue/composition-api'
import { BModal, BTable } from 'bootstrap-vue'
import ExcelJS from 'exceljs'
import { mapGetters, mapState } from 'vuex'
import LayoutDataTable from '../../layouts/table/LayoutDataTable.vue'
import OrdersDetail from './Detail/index.vue'
import useOrdersList from './useOrdersList'

export default {
  name: 'OrdersMarketPlace',
  components: {
    LayoutDataTable,
    BTable,
    BModal,
    OrdersDetail
  },
  data() {
    return {
      appUtils,
      ORDER_FORM_PROCESSOR_STATUS,
      ORDERS_PRODUCT_STATUS,
      currLanguage: this.$store.state.language.currLanguage,
      selectedRows: [],
      user: {},
      currentOrderId: 0,
      reject_reason: '',
      id_cancel: 0
    }
  },
  computed: {
    ...mapGetters('app', ['statusOptions']),
    ...mapGetters('orders', ['filterList']),
    ...mapState({
      orders: state => state.orders
    })
  },
  async created() {
    this.user = appUtils.getLocalUser()
    EventBus.$on('changeOrderStatus', this.refreshTable)

    this.handleCheckShowDetail()
  },
  destroyed() {
    EventBus.$off('changeOrderStatus', this.refreshTable)
  },
  methods: {
    handleTabClicked(e) {
      switch (Number(e.target.ariaPosInSet)) {
        case 1:
          this.followMode = ''
          break
        case 2:
          this.followMode = 'follow'
          break
        case 3:
          this.followMode = 'floating'
          break
        default:
          this.followMode = ''
          break
      }
      this.selectedRows = []
    },
    checkRow(eventValue, row) {
      if (eventValue) {
        this.selectedRows.push(row)
        this.refOrdersTable.localItems[index].isSelected = true
      } else {
        this.selectedRows = this.selectedRows.filter(item => item.id !== row.id)
        this.refOrdersTable.localItems[index].isSelected = false
      }
    },
    getIsFollow(followers) {
      return followers?.map(f => f.user_id).includes(this.user.id) || false
    },
    async handleClickBellIcon(orderId, isFollow) {
      const param = {
        object_id: orderId ? [orderId] : this.selectedRows.map(row => row.id),
        type: 1
      }
      if (isFollow) {
        await this.$rf.getRequest('CommonRequest').unfollowOrders(param)
      } else {
        await this.$rf.getRequest('CommonRequest').followOrders(param)
      }
      this.refetchData()
    },
    getCustomer(row) {
      if (row.order_contact) {
        const customerSender = row.order_contact.find(x => x.type === 2)
        return customerSender?.name
      } else {
        return ''
      }
    },
    getCustomerAddress(row) {
      if (row.order_contact) {
        const customerSender = row.order_contact.find(x => x.type === 1)
        return customerSender?.address1 || customerSender?.address2
      } else {
        return ''
      }
    },
    getCustomerPhone(row) {
      if (row.order_contact) {
        const customerSender = row.order_contact.find(x => x.type === 1)
        return customerSender?.phone
      } else {
        return ''
      }
    },
    // getTestStatus(row) {
    //   const leastStepTests = row.order_item.map((item) => {
    //     return item.order_processor_status
    //   })
    //   const minStep = Math.min(...leastStepTests)
    //   return minStep ? minStep : row?.status
    // },
    getDeliveryStatus(row) {
      switch (row.delivery_material_status) {
        case ORDERS_PRODUCT_STATUS.RECEIVE_ORDERS:
          return {
            title: this.$t('obj_order_status.receive_order'),
            color: 'primary'
          }
        case ORDERS_PRODUCT_STATUS.PROCESSING:
          return {
            title: 'Đã xử lý',
            // title: this.$t('obj_order_status.processing'),
            color: 'info'
          }
        case ORDERS_PRODUCT_STATUS.STOCK_OUT:
          return {
            title: this.$t('obj_order_status.stock_out'),
            color: 'info'
          }
        case ORDERS_PRODUCT_STATUS.DELIVERY:
          return {
            title: this.$t('obj_order_status.delivery'),
            color: 'info'
          }
        // case ORDERS_PRODUCT_STATUS.PAYMENT:
        //   return {
        //     title: this.$t("obj_order_status.payment"),
        //     color: "info"
        //   };
        case ORDERS_PRODUCT_STATUS.FINISH:
          return {
            title: this.$t('obj_order_status.finish'),
            color: 'success'
          }
        case ORDERS_PRODUCT_STATUS.CANCELED:
          return {
            title: this.$t('obj_order_status.canceled'),
            color: 'danger'
          }
        default:
          return {
            title: this.$t('obj_order_status.receive_order'),
            color: 'primary'
          }
      }
    },
    getPaymentStatus(row) {
      let status = {
        title: '',
        color: null
      }
      switch (row.payment?.status) {
        case this.paymentStatusFilterOptions[0].value:
          status = {
            color: 'warning',
            title: this.paymentStatusFilterOptions[0].text
          }
          break
        case this.paymentStatusFilterOptions[1].value:
          status = {
            color: 'danger',
            title: this.paymentStatusFilterOptions[1].text
          }

          break
        case this.paymentStatusFilterOptions[2].value:
          status = {
            color: 'success',
            title: this.paymentStatusFilterOptions[2].text
          }
          break
        default:
          // status.color = "primary";
          break
      }
      return status
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 2000)
    },
    formatDate(time, format = 'DD/MM/YYYY') {
      if (!time) return '_'
      const dateFormat =
        String(time)?.length === 13 ? Math.round(time / 1000) : time

      const date = moment.unix(dateFormat).format(format)
      return date
    },
    handleViewOrder(item, index) {
      this.allOpenRows.map(ele => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
      this.allOpenRows = []
      this.$set(item, '_showDetails', !item._showDetails)
      this.allOpenRows.push(item)
    },
    async deleteOrders(record) {
      if (!record?.id) {
        return
      }
      this.id_cancel = record.id
      this.$bvModal.show('modal-reject-reason')
    },
    resetModal() {
      this.reject_reason = ''
    },
    async handleConfirm() {
      try {
        await this.$rf.getRequest('CommonRequest').cancelOrder({
          order_id: this.id_cancel,
          reject_reason: this.reject_reason
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Huỷ đơn hàng thành công'),
            icon: 'CheckIcon',
            variant: 'success'
          }
        })
        this.refetchData()
      } catch (error) {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Huỷ đơn hàng không thành công'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    openDetailOrders(order) {
      this.$store.commit('orders/updateFilterList', {
        keyword: this.searchQuery,
        mode: this.followMode,
        page_size: this.perPage,
        page_num: this.currentPage,
        supplier_id: appUtils.getLocalUser()?.supplierInfo?.id,
        sort_by: 'created_at',
        order: 'desc',
        status: this.statusFilter,
        payment_status: this.paymentStatusFilter,
        from_date: this.fromDate ? this.fromDate.getTime() : this.fromDate,
        to_date: this.toDate ? this.toDate.getTime() : this.toDate
      })
      this.showDetailOrder = true
      this.currentOrderId = order.id
      // this.viewDetailOrders(order)
      // this.$router.push({ name: 'orders-market-place-detail', params: { id: order.id } })
    },
    handleCheckShowDetail() {
      if (this.$route.query.orderId) {
        this.openDetailOrders({ id: Number(this.$route.query.orderId) })
      }
    },
    async handleExportExcel() {
      try {
        this.isLoading = true
        const params = {
          keyword: this.searchQuery,
          mode: this.followMode,
          page_size: 10000,
          page_num: 1,
          supplier_id: appUtils.getLocalUser()?.supplierInfo?.id,
          sort_by: "id",
          order: "desc",
          delivery_material_status: this.statusFilter,
          payment_status: this.paymentStatusFilter,
          from_date: this.fromDate ? this.fromDate.getTime() : this.fromDate,
          to_date: this.toDate
            ? moment(this.toDate).endOf("day").valueOf()
            : this.toDate,
          type: PRODUCT_TYPES.PRODUCT,
        };
        const res = await this.$rf
          .getRequest("CommonRequest")
          .getOrders(params)

        const ordersReport = res?.data?.data

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Sheet1')

        // Add Hodo logo image
        const imageId = workbook.addImage({
          base64: HODO_LOGO_BASE64,
          extension: 'png'
        })
        worksheet.addImage(imageId, 'A1:A3')

        // Access columns from B to J and set their widths
        const defaultCols = 20
        const defaultColWidth = 22
        for (let index = 1; index <= defaultCols; index++) {
          const column = worksheet.getColumn(index + 1) // Columns are 1-indexed
          column.width = defaultColWidth
        }

        // Add a additional rows and merged cells with style before main table
        const companyNameCell = worksheet.getCell('B1')
        companyNameCell.value = 'CÔNG TY CỔ PHẦN CÔNG NGHỆ 905 VIỆT NAM'
        companyNameCell.font = { size: 13 }
        worksheet.mergeCells('B1:D1')

        const companyAddressCell = worksheet.getCell('B2')
        companyAddressCell.value =
          'Tầng 8, tòa nhà ACCI, số 210 Lê Trọng Tấn, Khương Mai, Thanh Xuân, Hà Nội'
        companyAddressCell.font = { size: 13 }
        worksheet.mergeCells('B2:E2')

        const companyContactCell = worksheet.getCell('B3')
        companyContactCell.value =
          'Tel: 0345.900.905                    Website: https://hodo.com.vn/'
        companyContactCell.font = { size: 13 }
        worksheet.mergeCells('B3:D3')

        const titleCell = worksheet.getCell('E5')
        titleCell.value = 'CHI TIẾT ĐƠN HÀNG TRÊN SÀN THƯƠNG MẠI ĐIỆN TỬ HODO'
        titleCell.font = { size: 14, bold: true }
        worksheet.mergeCells('E5:H5')

        const fromDate = worksheet.getCell('D6')
        fromDate.value = 'Từ ngày:'
        const fromDateValueCell = worksheet.getCell('E6')
        fromDateValueCell.value = this.fromDate
          ? window.moment(this.fromDate).format('DD/MM/YYYY')
          : ''

        const toDate = worksheet.getCell('G6')
        toDate.value = 'Đến ngày:'
        const toDateValueCell = worksheet.getCell('H6')
        toDateValueCell.value = this.toDate
          ? window.moment(this.toDate).format('DD/MM/YYYY')
          : ''

        // Add additional rows with bold style
        const customerData = [
          [
            '',
            'Tên nhà cung cấp:',
            appUtils.getLocalUser()?.supplierInfo?.name || '',
            '',
            '',
            '',
            '',
            // 'Tổng doanh thu:',
            // appUtils.numberFormat(this.grandTotal) + ' VND'
          ],
          [
            '',
            'Mã số:',
            appUtils.getLocalUser()?.supplierInfo?.ein || '',
            '',
            '',
            '',
            ''
          ],
          ['', 'Địa chỉ:', appUtils.getLocalUser()?.supplierInfo?.address || ''],
          []
        ]
        worksheet.addRows(customerData)

        // Generate the main table (your data) with borders and style
        // let completedOrders = saleReportData.filter(order =>
        //   ['3', '4'].includes(order.status)
        // )

        let completedOrders = ordersReport
        const rawData = completedOrders?.map((order, i) => {
          const totalOrderAmount = Number(this.getTotalOrderAmount(order))
          const totalPointTxn = Number(this.getTotalPointTxn(order))
          const totalCampaignTnx = Number(this.getTotalCampaignTnx(order))
          const slipAmount = Number(this.getSlipAmount(order))
          return [
            i + 1,
            order?.order_code || '',
            this.getCustomer(order),
            order?.order_date
              ? window.moment(order?.order_date).format('DD/MM/YYYY')
              : '',
            this.getDeliveryStatus(order).title || '',
            this.getPaymentStatus(order).title || '',
            totalOrderAmount,
            totalPointTxn,
            totalCampaignTnx,
            slipAmount,
            order?.slip?.number_code
          ]
        })

        const mainTableData = [
          [
            'STT',
            'MÃ ĐƠN HÀNG',
            'TÊN NGƯỜI MUA',
            'NGÀY TẠO ĐƠN',
            'TRẠNG THÁI ĐƠN HÀNG',
            'TRẠNG THÁI THANH TOÁN',
            'TỔNG GIÁ TRỊ (VNĐ)',
            'H-CREDIT',
            'VOUCHER',
            'KHÁCH HÀNG TRẢ (VNĐ)',
            'MÃ PHIẾU THU',
          ],
          ...rawData
        ]

        worksheet.addRows(mainTableData)
        const mainTable = worksheet.getRows(11, mainTableData.length)
        mainTable.forEach((row, rowIndex) => {
          if (rowIndex) {
            row.eachCell(cell => {
              cell.border = {
                top: { style: 'hair' },
                left: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'hair' }
              }
              cell.alignment = { horizontal: 'center', vertical: 'center' }
            })
          } else {
            row.eachCell(cell => {
              cell.font = { bold: true }
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'hair' }
              }
              cell.alignment = {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true
              }
            })
          }
        })
        const headerRow = worksheet.getRow(11)
        headerRow.height = 32

        // default styles
        workbook.eachSheet(sheet => {
          sheet.eachRow(row => {
            row.eachCell(cell => {
              if (!cell.font?.name) {
                cell.font = Object.assign(cell.font || {}, {
                  name: 'Times New Roman'
                })
              }
            })
          })
        })
        // worksheet.getColumn(8).numFmt = '#.##0,00"đ"';
        // worksheet.getColumn(9).numFmt = '#.##0,00"đ"';
        worksheet.properties.defaultRowHeight = 16

        // Save the workbook to a file
        await workbook.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Product-Orders-Report-${moment().format('DDMMYYYYHHmmss')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getTotalOrderAmount(data) {
      if (!data?.id) return "-"
      const amount = data?.slip?.amount || 0
      const total = amount + (isNaN(this.getTotalPointTxn(data)) ? 0 : this.getTotalPointTxn(data)) + (isNaN(this.getTotalCampaignTnx(data)) ? 0 : this.getTotalCampaignTnx(data))
      return total
    },
    getTotalPointTxn(data) {
      if (!data?.id) return 0
      const txnPoints = data?.order_item?.map(item => item?.service_transactions?.points_transactions?.transaction_point || 0)
      const total = txnPoints?.reduce((a, b) => a + b, 0)
      return total
    },
    getTotalCampaignTnx(data) {
      if (!data?.id) return 0
      const txtCampaigns = data?.order_item?.map(item => item?.service_transactions?.campaign_transactions?.campaign_transaction_value || 0)
      const total = txtCampaigns?.reduce((a, b) => a + b, 0)
      return total
    },
    getSlipAmount(data) {
      if (!data?.id) return 0
      return data?.slip?.amount || 0
    }
    // getResultDateInfor(return_date, row = null) {
    //   const test_status = row ? this.getTestStatus(row) : 0
    //   const time = return_date - Date.now()
    //   if (
    //     !return_date ||
    //     [
    //       ORDERS_STATUS.FINISHED,
    //       ORDERS_STATUS.CANCELED,
    //       ORDER_FORM_PROCESSOR_STATUS.SUPPLIER_CANCEL
    //     ].includes(test_status)
    //   ) {
    //     return {
    //       isShowBadge: false
    //     }
    //   } else if (time < -86400000) {
    //     return {
    //       isShowBadge: true,
    //       variant: 'red',
    //       tooltip: this.$t('lbl_the_deadline_for_returning_results_has_passed')
    //     }
    //   } else if (time < 0) {
    //     return {
    //       isShowBadge: true,
    //       variant: 'red',
    //       tooltip: this.$t('lbl_results_are_due_today')
    //     }
    //   } else if (time < 86400000 * 3) {
    //     return {
    //       isShowBadge: true,
    //       variant: '#ffc107',
    //       tooltip: this.$t(
    //         'lbl_there_is_1_day_left_until_the_appointment_to_return_the_results',
    //         [Math.ceil(time / 86400000)]
    //       )
    //     }
    //   } else {
    //     return {
    //       isShowBadge: false
    //     }
    //   }
    // },
  },
  watch: {
    '$store.state.language.currLanguage': function () {
      this.currLanguage = this.$store.state.language.currLanguage
    },
    $route: {
      deep: true,
      handler() {
        this.handleCheckShowDetail()
      }
    },
    statusFilter() {
      this.currentPage = 1
    },
    fromDate() {
      this.currentPage = 1
    },
    toDate() {
      this.currentPage = 1
    },
    paymentStatusFilter() {
      this.currentPage = 1
    }
  },
  setup() {
    const dataTag = ref({})
    const {
      timeout,
      showTable,
      fetchOrdersList,
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      searchQuery,
      followMode,
      sortBy,
      isSortDirDesc,
      refOrdersTable,
      refetchData,
      allOpenRows,
      ORDERS_STATUS,
      getOrderStatus,
      recordDetail,
      showDetailOrder,
      statusFilterOptions,
      statusFilter,
      paymentStatusFilter,
      paymentStatusFilterOptions,
      fromDate,
      toDate,
      isLoading,
      viewDetailOrders,
      listOrders
    } = useOrdersList()

    return {
      timeout,
      showTable,
      dataTag,
      fetchOrdersList,
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      searchQuery,
      followMode,
      sortBy,
      isSortDirDesc,
      refOrdersTable,
      refetchData,
      allOpenRows,
      ORDERS_STATUS,
      getOrderStatus,
      recordDetail,
      showDetailOrder,
      statusFilterOptions,
      statusFilter,
      paymentStatusFilter,
      paymentStatusFilterOptions,
      fromDate,
      toDate,
      isLoading,
      viewDetailOrders,
      listOrders
    }
  }
}
</script>

<style lang="scss">
.avatar {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  top: -20px;
}

.b-table-details {
  background-color: #f6f6f9;
}

.padding-sm {
  padding: 0.72rem 0.5rem !important;
}

.margin-b-4 {
  margin-bottom: 4px;
}

.fade-loading {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #2cccff;
  border-radius: 100rem;
  margin-right: -11px;
  transform: translate(-16px, -1px);
  position: relative;
}

.fade-loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: inherit;
  animation: fade 1s forwards infinite linear;
}

@keyframes fade {
  to {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
